import { memo } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Img } from '../../assets'

import { PageTitle } from '../text'

const Container = styled.div<{ width: number }>`
  margin-top: 100px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: flex-start;
  margin-left: -16px;
  margin-right: -16px;
  width: 100%;
`

const MajorSponsorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  width: 75%;
  padding-left: 16px;
  padding-right: 16px;
`

const MinorSponsorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 25%;
  padding-left: 16px;
  padding-right: 16px;
`

const StyledPageTitle = styled(PageTitle)`
  color: ${({ theme }) => theme.palettes.general.black};
`

const FlexRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-left: -16px;
  margin-right: -16px;
`

const FlexItem = styled.div`
  display: flex;
  flex: 1;
  width: 33%;
  padding-left: 16px;
  padding-right: 16px;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

export const SponsorFooter = memo(() => {
  const { t } = useTranslation()
  const width = window.innerWidth

  return (
    <Container width={width}>
      <MajorSponsorWrapper>
        <StyledPageTitle>{t('footer.sponsor.coOrganize')}</StyledPageTitle>
        <FlexRow>
          <FlexItem>
            <Image src={Img.TheHKUniversity} alt="the-hk-university" />
          </FlexItem>
          <FlexItem>
            <Image
              src={Img.TheHKPolytechnicUniversity}
              alt="the-hk-polytechnic-university"
            />
          </FlexItem>
          <FlexItem>
            <Image
              src={Img.TheEducationUniversityOfHK}
              alt="the-education-university-of-hk"
            />
          </FlexItem>
        </FlexRow>
      </MajorSponsorWrapper>
      <MinorSponsorWrapper>
        <StyledPageTitle>{t('footer.sponsor.agency')}</StyledPageTitle>
        <Image src={Img.JcPrimary} alt="jc-primary-logo" />
      </MinorSponsorWrapper>
    </Container>
  )
})
