import { setApiConfig } from '@polyu-dip/apis'
import { useEffect, useState } from 'react'
import { env } from '../utilities'
import { init, InitResult } from './init'

export function useInit() {
  const [initResult, setInitResult] = useState<InitResult | undefined>()

  useEffect(() => {
    ;(async () => {
      setApiConfig({
        baseUrl: env.apiBaseUrl,
        timeout: +env.apiTimeout,
      })

      const { queryClient, rootStore } = await init()

      setInitResult({ queryClient, rootStore })
    })()
  }, [])

  return initResult
}
