import { LessonPlan, RoleType } from '@polyu-dip/models'
import { useCallback, useMemo } from 'react'
import { useStores } from '../stores'

export function useLessonPlanPermission() {
  const { userProfileStore } = useStores()

  const currentUserProfile = useMemo(
    () => userProfileStore.userProfile,
    [userProfileStore.userProfile],
  )

  const lessonPlanPermissionHandler = useCallback(
    (lessonPlan?: LessonPlan) => {
      if (lessonPlan == null)
        return {
          canViewLessonPlan: false,
          canEditLessonPlan: false,
          canEditLessonPlanExample: false,
          canCommentLessonPlan: false,
          canViewTeachingClass: false,
          isShared: false,
          canCopyLessonPlanExample: false,
          canCompleteLessonPlanConsult: false,
        }
      const isShared = lessonPlan.lessonPlanSharings.some(
        (it) => it.sharedToUserId === currentUserProfile?.id,
      )
      const canViewLessonPlan =
        lessonPlan.ownedByUserId === currentUserProfile?.id ||
        isShared ||
        lessonPlan.lessonPlanConsults.some(
          (it) => it.assignedExpertUserId === currentUserProfile?.id,
        ) ||
        currentUserProfile?.role?.label === RoleType.diTeam

      const canEditLessonPlan =
        lessonPlan.ownedByUserId === currentUserProfile?.id

      const canEditLessonPlanExample =
        currentUserProfile?.role?.label === RoleType.diTeam

      const canCommentLessonPlan =
        lessonPlan.ownedByUserId === currentUserProfile?.id ||
        lessonPlan.lessonPlanSharings.some(
          (it) => it.sharedToUserId === currentUserProfile?.id,
        ) ||
        lessonPlan.lessonPlanConsults.some(
          (it) => it.assignedExpertUserId === currentUserProfile?.id,
        ) ||
        currentUserProfile?.role?.label === RoleType.diTeam

      const canViewTeachingClass =
        lessonPlan.ownedByUserId === currentUserProfile?.id ||
        lessonPlan.ownedByUser?.schoolId == currentUserProfile?.schoolId

      const canCopyLessonPlanExample =
        currentUserProfile?.role?.label === RoleType.teacher

      const canCompleteLessonPlanConsult =
        currentUserProfile?.role?.label === RoleType.expert

      return {
        canViewLessonPlan,
        canEditLessonPlan,
        canEditLessonPlanExample,
        canCommentLessonPlan,
        canViewTeachingClass,
        isShared,
        canCopyLessonPlanExample,
        canCompleteLessonPlanConsult,
      }
    },
    [
      currentUserProfile?.id,
      currentUserProfile?.role?.label,
      currentUserProfile?.schoolId,
    ],
  )

  return { lessonPlanPermissionHandler }
}
