import {
  getMyNotifications,
  NotificationQueryParameters,
  NotificationResponsePayload,
} from '@polyu-dip/apis'
import { QueryKey, useQuery, UseQueryOptions } from '@tanstack/react-query'

const QUERY_CACHE_KEY_ROOT = 'notifications'

export function useMyNotifications(
  param?: NotificationQueryParameters,
  opts?: UseQueryOptions<NotificationResponsePayload | null>,
) {
  return useQuery(
    [QUERY_CACHE_KEY_ROOT, 'me', param] as QueryKey,
    () => getMyNotifications(param),
    opts,
  )
}
