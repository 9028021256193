import { GetPayload, PaginatedPayload, PaginationParameters } from '../base'
import {
  File,
  LessonPlan,
  LessonPlanComment,
  Notification,
  Role,
  User,
} from '../entity'

type ExpandableKey =
  | 'CreatedByUser'
  | 'CreatedByUser.Role'
  | 'ReceivedByUser'
  | 'LessonPlan'
  | 'Comment'
  | 'File'

export type NotificationType =
  | 'share'
  | 'comment'
  | 'repliedComment'
  | 'submittedPublishRequest'
  | 'withdrawPublishRequest'
  | 'approvedPublishRequest'
  | 'rejectedPublishRequest'
  | 'published'
  | 'unpublished'
  | 'consult'
  | 'assigned'
  | 'newLessonPlanFromOwner'
  | 'newLessonPlanFromSchoolAdmin'
  | 'lessonPlanTransferred'
  | 'userAccountDisabled'
  | 'userAccountReEnabled'

export enum NotificationTypeEnum {
  share = 'share',
  comment = 'comment',
  repliedComment = 'repliedComment',
  submittedPublishRequest = 'submittedPublishRequest',
  withdrawPublishRequest = 'withdrawPublishRequest',
  approvedPublishRequest = 'approvedPublishRequest',
  rejectedPublishRequest = 'rejectedPublishRequest',
  published = 'published',
  unpublished = 'unpublished',
  consult = 'consult',
  assigned = 'assigned',
  newLessonPlanFromOwner = 'newLessonPlanFromOwner',
  newLessonPlanFromSchoolAdmin = 'newLessonPlanFromSchoolAdmin',
  lessonPlanTransferred = 'lessonPlanTransferred',
  userAccountDisabled = 'userAccountDisabled',
  userAccountReEnabled = 'userAccountReEnabled',
}

export type NotificationExpandParameter = {
  expand?: ExpandableKey[]
}

export type NotificationQueryParameters = PaginationParameters &
  Partial<Notification> &
  NotificationExpandParameter

export type NotificationPayload = GetPayload<Notification> & {
  createdByUser?: GetPayload<User> & {
    role: GetPayload<Role>
  }
  receivedByUser?: GetPayload<User>
  lessonPlan?: GetPayload<LessonPlan>
  comment?: GetPayload<LessonPlanComment>
  file?: GetPayload<File>
}

export type NotificationResponsePayload = PaginatedPayload<NotificationPayload>
