import { useComputed } from '@polyu-dip/helpers'
import { observer } from 'mobx-react-lite'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { Svg } from '../../../assets'
import { contentPaths } from '../../../content-paths'
import { contentPermissions } from '../../../content-permissions'
import { useStores } from '../../../stores'
import { env } from '../../../utilities'
import { SidebarLink } from './sidebar-link'

const SideDiv = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  z-index: 999;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.4);
  min-width: 272px;
  max-width: 272px;
  overflow: hidden;
  position: fixed;
  padding: 20px 32px;
  height: 100vh;
  background-color: ${({ theme }) => theme.palettes.general.white};
`

const SideContentContainer = styled.div``

const SideContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 200px;
  left: 32px;
  top: 150px;
  height: 70%;
  overflow: auto;
`

const SideNavigationDiv = styled.div``

const SideBarBannerDiv = styled.div`
  display: flex;
  justify-content: space-around;
  color: #5a3a1b;
  align-items: center;
  max-width: 200px;
  font-size: 24px;
`

const SideBarFooterDiv = styled.div`
  display: flex;
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

const StyledEnvironment = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.spacings.general[2]}px;
`

const StyledVersion = styled.div`
  display: flex;
  align-items: center;
  padding-right: ${({ theme }) => theme.spacings.general[6]}px;
`

enum UserRole {
  Teacher = 'Teacher',
  DI_Team = 'DI_Team',
  School_Management_Team = 'School_Management_Team',
  School_Admin = 'School_Admin',
  Expert = 'Expert',
}

type SideBarPropType = {}
export const SideBar = observer<SideBarPropType>(() => {
  const { t } = useTranslation()

  const { userProfileStore } = useStores()

  const currentUserRole = useComputed(() => {
    return userProfileStore.userProfile?.role?.label
  }, [userProfileStore.userProfile?.role?.label])

  const currentUserIsDisabled = useComputed(() => {
    return userProfileStore.userProfile?.isAccountDisabled
  }, [userProfileStore.userProfile])

  const navigations = useMemo(
    () => [
      {
        id: 10,
        iconSrc:
          currentUserRole == UserRole.School_Admin
            ? Svg.User
            : Svg.DashboardBlack,
        enabledIconSrc:
          currentUserRole == UserRole.School_Admin
            ? Svg.User
            : Svg.DashboardWhite,
        src: contentPaths.home(),
        text:
          currentUserRole == UserRole.School_Admin
            ? t('sidebar.schoolUser')
            : t('sidebar.home'),
        permittedRoles: contentPermissions.home,
      },
      {
        id: 20,
        iconSrc: Svg.Draft,
        src: contentPaths.myLessonPlan(),
        text: t('sidebar.myLessonPlan'),
        permittedRoles: contentPermissions.myLessonPlan,
      },
      {
        id: 25,
        iconSrc: Svg.Draft,
        src: contentPaths.disposedLessonPlan(),
        text: t('sidebar.disposedLessonPlan'),
        permittedRoles: contentPermissions.disposedLessonPlan,
      },
      {
        id: 30,
        iconSrc: Svg.Draft,
        src: contentPaths.lessonPlanConsultRequests(),
        text:
          currentUserRole === UserRole.DI_Team
            ? t('sidebar.consultManagement')
            : t('sidebar.consultFollowingUp'),
        permittedRoles: contentPermissions.lessonPlanConsultRequest,
      },
      {
        id: 35,
        iconSrc: Svg.PublishBlack,
        enabledIconSrc: Svg.Publish,
        src: contentPaths.lessonPlanPublishRequests(),
        text: t('sidebar.publishManagement'),
        permittedRoles: contentPermissions.lessonPlanPublishRequest,
      },
      {
        id: 40,
        iconSrc: Svg.School,
        src: contentPaths.lessonPlanSharing(),
        text: t('sidebar.lessonPlanSharing'),
        permittedRoles: contentPermissions.lessonPlanSharing,
      },
      {
        id: 45,
        iconSrc: Svg.Report,
        src: contentPaths.lessonPlanExampleManagements(),
        text: t('sidebar.lessonPlanExamples'),
        permittedRoles: contentPermissions.lessonPlanExampleManagement,
      },
      {
        id: 50,
        iconSrc: Svg.Report,
        src: contentPaths.lessonPlanExamples(),
        text: t('sidebar.lessonPlanExamples'),
        permittedRoles: contentPermissions.lessonPlanExamples,
      },
      {
        id: 60,
        iconSrc: Svg.DI,
        src: contentPaths.diStrategies(),
        text: t('sidebar.diStrategies'),
        permittedRoles: contentPermissions.diStrategies,
      },
      {
        id: 65,
        iconSrc: Svg.Resource,
        src: contentPaths.resourcesManagement(),
        text: t('sidebar.resources'),
        permittedRoles: contentPermissions.resourcesManagement,
      },
      {
        id: 70,
        iconSrc: Svg.Resource,
        src: contentPaths.resources(),
        text: t('sidebar.resources'),
        permittedRoles: contentPermissions.resources,
      },
      {
        id: 80,
        text: t('sidebar.systemParameter'),
        iconSrc: Svg.Data,
        src: contentPaths.masterDataManagement(),
        textColor: '#5A3A1B',
        permittedRoles: contentPermissions.masterDataManagement,
      },
      {
        id: 90,
        iconSrc: Svg.School,
        src: contentPaths.schools(),
        text: t('sidebar.schoolSummary'),
        permittedRoles: contentPermissions.schoolManagement,
      },
      {
        id: 95,
        iconSrc: Svg.User,
        src: contentPaths.systemUsers(),
        text: t('sidebar.systemUser'),
        permittedRoles: contentPermissions.userManagement,
      },
      {
        id: 100,
        iconSrc: Svg.LessonPlans,
        src: contentPaths.submissionRecords(),
        text: t('sidebar.submitRecord'),
        permittedRoles: contentPermissions.submissionRecords,
      },
      {
        id: 120,
        iconSrc: Svg.Comment,
        src: contentPaths.contactUs(),
        text: t('sidebar.contactUs'),
        permittedRoles: contentPermissions.contactUs,
      },
      {
        id: 130,
        iconSrc: Svg.Disclaimer,
        src: contentPaths.disclaimer(),
        text: t('sidebar.disclaimer'),
        permittedRoles: contentPermissions.disclaimer,
      },
    ],
    [currentUserRole, t],
  )

  const location = useLocation()
  const currentPathIndex = useMemo(() => {
    const currentLocation = navigations
      .slice(1)
      .find((it) => location.pathname.includes(it.src))
    if (currentLocation == null) return navigations[0]?.id ?? 1
    return currentLocation.id
  }, [location.pathname, navigations])

  const [currentIndex, setCurrentIndex] = useState(currentPathIndex)
  const [isMainFocus, setMainFocus] = useState(true)
  const main = 'main'

  const updateCurrentIndex = useCallback((index: number, type: string) => {
    setCurrentIndex(index)
    if (type == main) {
      setMainFocus(true)
    } else {
      setMainFocus(false)
    }
  }, [])

  useEffect(() => {
    const currentLocation = navigations
      .slice(1)
      .find((it) => location.pathname.includes(it.src))
    if (currentLocation == null) {
      setCurrentIndex(navigations[0]?.id ?? 1)
    } else {
      setCurrentIndex(currentLocation.id)
    }
  }, [location.pathname, navigations])

  const navigationList = currentUserIsDisabled
    ? []
    : navigations
        .filter((link) =>
          link.permittedRoles.some(
            (role) => role == userProfileStore.userProfile?.role?.label,
          ),
        )
        .sort((a, b) => a.id - b.id)
        .map((navigation) => {
          const isActive =
            isMainFocus && (currentIndex == navigation.id ? true : false)
          return (
            <SidebarLink
              key={navigation.id}
              text={navigation.text}
              to={navigation.src}
              iconSrc={
                isActive && navigation.enabledIconSrc != null
                  ? navigation.enabledIconSrc
                  : navigation.iconSrc
              }
              isActive={isActive}
              isFocus={isMainFocus}
              onClick={() => updateCurrentIndex(navigation.id, main)}
            />
          )
        })

  return (
    <>
      <SideDiv>
        <SideBarBannerDiv>
          <Svg.Logo width="48" height="48" />
          DI Planner
        </SideBarBannerDiv>
        <SideContentContainer>
          <SideContentDiv>
            <SideNavigationDiv>{navigationList}</SideNavigationDiv>
          </SideContentDiv>
          <SideBarFooterDiv>
            <StyledEnvironment>{env.environment}</StyledEnvironment>
            <StyledVersion>
              {t('common.version', { version: env.version })}
            </StyledVersion>
          </SideBarFooterDiv>
        </SideContentContainer>
      </SideDiv>
    </>
  )
})
