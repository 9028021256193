import { Button, Grid } from '@mui/material'
import { useCallback, useMemo } from 'react'
import { useComputed } from '@polyu-dip/helpers'
import { useTheme } from '@polyu-dip/theme'
import { observer } from 'mobx-react-lite'
import {
  Controller,
  SubmitHandler,
  useFieldArray,
  useForm,
  useFormContext,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Svg } from '../../../../assets'
import { FormLabel, Tag, TextField, useOverlay } from '../../../../components'
import { useStores } from '../../../../stores'
import { RequestConsultingButton, SectionBox } from '../../components'
// eslint-disable-next-line import/no-internal-modules
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useLessonPlanFormContext } from '../../use-lesson-plan-form-context'
import {
  clearLessonPlansQueryCaches,
  useCreateLessonPlanSharingsById,
  useDeleteLessonPlanSharings,
  useMySchool,
} from '@polyu-dip/queries'
import { LessonPlanSharing } from '@polyu-dip/models'
import { useQueryClient } from '@tanstack/react-query'
import {
  useApiErrorHandle,
  useFormErrorTranslationTrigger,
  useLessonPlanPermission,
} from '../../../../hooks'
import { useDisplayName } from '../../../../services'
import {
  SharedToUsersFormData,
  UserSelectionDropdown,
} from './user-selection-dropdown'
import { GeneralInformationFormData } from '../../lesson-plan-form-provider'

const FormRoot = styled.form``

const StyledTag = styled(Tag)`
  margin-bottom: 10px;
  .MuiChip-deleteIcon {
    margin: 0px;
  }
`

const StyledCloseIcon = styled.div`
  display: flex;
`

type Props = {
  isExample?: boolean
}

export const ShareAndRequestPublish = observer<Props>(({ isExample }) => {
  const { t } = useTranslation()
  const { standardErrorHandler } = useApiErrorHandle()
  const { getDisplayName } = useDisplayName()

  const schema: yup.SchemaOf<SharedToUsersFormData> = useMemo(
    () =>
      yup.object({
        users: yup
          .array(
            yup
              .object({
                value: yup.string().required(t('error.required')),
                label: yup.string().required(),
              })
              .required(t('error.required')),
          )
          .required(),
      }),
    [t],
  )
  const theme = useTheme()
  const { userProfileStore } = useStores()

  const { showSnackbar, showSpinner, hideSpinner } = useOverlay()

  const { lessonPlanPermissionHandler } = useLessonPlanPermission()
  const { lessonPlanFormData } = useLessonPlanFormContext()

  const { data: currentSchool, isLoading: isCurrentSchoolLoading } =
    useMySchool(useStores, {
      expand: ['Users'],
    })

  const sharedUsers: LessonPlanSharing[] = useComputed(() => {
    return lessonPlanFormData?.lessonPlanSharings
      ?.map((lessonPlanSharing: LessonPlanSharing) => ({
        ...lessonPlanSharing,
        sharedToUser: {
          ...lessonPlanSharing.sharedToUser,
          school: { ...lessonPlanSharing.sharedToUser?.school },
        },
        createdTimeDayJs: lessonPlanSharing.createdTimeDayJs,
      }))
      .slice()
      .sort((a: LessonPlanSharing, b: LessonPlanSharing) => {
        return a.createdTimeDayJs.isBefore(b.createdTimeDayJs) ? -1 : 1
      })
  }, [lessonPlanFormData])

  const filteredUserIds = useMemo(() => {
    return [
      userProfileStore.userProfile?.id ?? '',
      ...sharedUsers.map((it) => it.sharedToUserId),
    ]
  }, [sharedUsers, userProfileStore.userProfile?.id])

  const sharedToUserOptions = useComputed(
    () =>
      isCurrentSchoolLoading
        ? []
        : currentSchool?.schoolUsers
            ?.filter(
              (user: any) =>
                !filteredUserIds.includes(user.id) && !user.isAccountDisabled,
            )
            .map((user: any) => ({
              label: t('users.userDropdownLabel', {
                name: user?.name,
                school: getDisplayName(user?.school?.name) ?? '---',
                email: user?.email,
              }),
              value: user.id,
            })) ?? [],
    [
      isCurrentSchoolLoading,
      currentSchool?.schoolUsers,
      filteredUserIds,
      t,
      getDisplayName,
    ],
  )

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    reset,
    trigger,
  } = useForm<SharedToUsersFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      users: [
        {
          label: undefined,
          value: undefined,
        },
      ],
    },
  })

  const { control: lpFormControl } =
    useFormContext<GeneralInformationFormData>()

  useFormErrorTranslationTrigger(errors, trigger)

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  })

  const queryClient = useQueryClient()

  const { mutateAsync: deleteLessonPlanSharings } = useDeleteLessonPlanSharings(
    useStores,
    {
      onSuccess() {
        showSnackbar({
          message: t('lessonPlan.createForm.updateSharingsSuccess'),
        })
        clearLessonPlansQueryCaches(queryClient, lessonPlanFormData.id)
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t(
            'lessonPlan.errorMessage.deleteLessonPlanSharings.title',
          ),
        })
      },
    },
  )

  const handleDelete = useCallback(
    async (id: string) => {
      showSpinner()
      try {
        await deleteLessonPlanSharings(id)
      } finally {
        hideSpinner()
      }
    },
    [deleteLessonPlanSharings, hideSpinner, showSpinner],
  )

  const { mutateAsync: createLessonPlanSharings } =
    useCreateLessonPlanSharingsById(useStores, lessonPlanFormData.id, {
      onSuccess() {
        showSnackbar({
          message: t('lessonPlan.createForm.sharingsSuccess'),
        })
        reset()
      },
      onError: (error) => {
        standardErrorHandler(error, {
          defaultTitle: t(
            'lessonPlan.errorMessage.createLessonPlanSharings.title',
          ),
        })
      },
    })

  const onSubmit: SubmitHandler<SharedToUsersFormData> = useCallback(
    async (data) => {
      if (!data.users.some((it) => it.value != null || it.value != '')) {
        return
      }
      showSpinner()
      try {
        await createLessonPlanSharings(
          data.users.map((selectedUser) => ({
            sharedToUserId: selectedUser.value,
          })),
        )
      } finally {
        hideSpinner()
      }
    },
    [createLessonPlanSharings, hideSpinner, showSpinner],
  )

  const filterOptions = useCallback(
    (options: { value: string; label: string }[]) => {
      return options.filter((option) => {
        return !watch('users').some(
          (selected) => selected.value === option.value,
        )
      })
    },
    [watch],
  )

  const { canEditLessonPlan } = lessonPlanPermissionHandler(lessonPlanFormData)

  if (isExample) {
    return (
      <FormRoot onSubmit={handleSubmit(onSubmit)}>
        <SectionBox>
          <Controller
            control={lpFormControl}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.shareAndRequestPublish.schoolBackgroundInformation',
                )}
                placeholder={t(
                  'lessonPlan.createForm.shareAndRequestPublish.schoolBackgroundInformation',
                )}
                multiline
                rows={6}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                inputProps={{ min: 25, step: 5 }}
              />
            )}
            name="schoolBackgroundInformation"
          />
        </SectionBox>
        <SectionBox>
          <Controller
            control={lpFormControl}
            render={({ field }) => (
              <TextField
                label={t(
                  'lessonPlan.createForm.shareAndRequestPublish.diStrategyTeachingConceptExcerpt',
                )}
                placeholder={t(
                  'lessonPlan.createForm.shareAndRequestPublish.diStrategyTeachingConceptExcerpt',
                )}
                multiline
                rows={6}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                inputProps={{ min: 25, step: 5 }}
              />
            )}
            name="diStrategyTeachingConceptExcerpt"
          />
        </SectionBox>
      </FormRoot>
    )
  }

  return (
    <FormRoot onSubmit={handleSubmit(onSubmit)}>
      <SectionBox>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          mb={6}
        >
          <Grid item>
            <FormLabel
              label={t('lessonPlan.createForm.shareAndRequestPublish.shareTo')}
            />
          </Grid>
          <Grid item>
            <RequestConsultingButton
              lessonPlanId={lessonPlanFormData.id}
              latestLessonPlanConsult={
                lessonPlanFormData.latestLessonPlanConsult
              }
              canConsult={canEditLessonPlan}
            />
          </Grid>
        </Grid>

        {sharedUsers.map((sharedUser) => (
          <StyledTag
            colorCustomize={theme.colors.tertiary}
            label={t('users.userDropdownLabel', {
              name: sharedUser.sharedToUser?.name,
              school:
                getDisplayName(sharedUser.sharedToUser?.school?.name) ?? '---',
              email: sharedUser.sharedToUser?.email,
            })}
            key={sharedUser.id}
            onDelete={() => handleDelete(sharedUser.id)}
            deleteIcon={
              <StyledCloseIcon>
                <Svg.Close
                  width={20}
                  height={20}
                  cursor="pointer"
                  fill={theme.palettes.general.black}
                />
              </StyledCloseIcon>
            }
          />
        ))}

        {fields.map((item, index) => (
          <UserSelectionDropdown
            key={index}
            item={item}
            index={index}
            control={control}
            errors={errors}
            remove={remove}
            isLoading={isCurrentSchoolLoading}
            sharedToUserOptions={sharedToUserOptions}
            filterOptions={filterOptions}
            filteredIds={filteredUserIds}
          />
        ))}
        <Grid container justifyContent="flex-end" mb={6}>
          <Svg.Plus
            cursor="pointer"
            onClick={() =>
              append({
                value: '',
                label: '',
              })
            }
            fill={theme.palettes.general.blue}
          />
        </Grid>
        <Grid container justifyContent="flex-end">
          <Grid item width={132}>
            <Button
              fullWidth
              color="blue"
              type="submit"
              disabled={
                lessonPlanFormData?.id == null ||
                lessonPlanFormData.id?.length === 0
              }
            >
              {t('lessonPlan.actions.sharedWithOthers')}
            </Button>
          </Grid>
        </Grid>
      </SectionBox>
    </FormRoot>
  )
})
