import { RoleType } from '@polyu-dip/models'

export const allRolePermitted = [
  RoleType.diTeam,
  RoleType.expert,
  RoleType.schoolAdmin,
  RoleType.teacher,
]

export const contentPermissions = {
  home: allRolePermitted,
  allLessonPlan: [RoleType.diTeam],
  myLessonPlan: [RoleType.teacher],
  disposedLessonPlan: [RoleType.schoolAdmin],
  editLessonPlans: [RoleType.teacher, RoleType.diTeam],
  lessonPlanSharing: [
    RoleType.diTeam,
    RoleType.expert,
    RoleType.schoolAdmin,
    RoleType.teacher,
  ],
  lessonPlanExampleManagement: [RoleType.diTeam],
  lessonPlanExamples: [RoleType.expert, RoleType.teacher],

  lessonPlanPublishCopy: [RoleType.teacher],
  lessonPlanConsultRequest: [RoleType.diTeam, RoleType.expert],
  lessonPlanPublishRequest: [RoleType.diTeam],
  resourcesManagement: [RoleType.diTeam],
  resources: [RoleType.expert, RoleType.teacher],

  schoolManagement: [RoleType.diTeam],
  masterDataManagement: [RoleType.diTeam],
  submissionRecords: [RoleType.diTeam],
  schoolUsers: [RoleType.schoolAdmin],
  systemUsers: [RoleType.diTeam],
  userManagement: [RoleType.diTeam],

  notifications: allRolePermitted,
  diStrategies: [RoleType.diTeam, RoleType.expert, RoleType.teacher],

  contactUs: [RoleType.diTeam, RoleType.expert, RoleType.teacher],
  disclaimer: [RoleType.diTeam, RoleType.expert, RoleType.teacher],
  health: allRolePermitted,
  login: allRolePermitted,
}
