import { RoleType } from '@polyu-dip/models'
import { observer } from 'mobx-react-lite'
import { useMemo } from 'react'
import { useStores } from '../../stores'
import { SchoolUsers } from '../users/school-users'
import { DiExpertHomePage } from './di-expert'
import { DiTeamHomePage } from './di-team'
import { TeacherHomePage } from './teacher'

export const HomePage = observer(() => {
  const { userProfileStore } = useStores()
  const currentUserRole = useMemo(
    () => userProfileStore.userProfile?.role?.label,
    [userProfileStore.userProfile],
  )

  if (currentUserRole === RoleType.diTeam) return <DiTeamHomePage />

  if (currentUserRole === RoleType.expert) return <DiExpertHomePage />

  if (currentUserRole === RoleType.schoolAdmin) return <SchoolUsers />
  return <TeacherHomePage />
})
