export const lessonPlan = {
  createForm: {
    steps: {
      generalInformation: 'Overview',
      diStrategies: 'DI strategies inventory',
      uploadDocument: 'Upload lesson materials',
      shareAndRequestPublish: 'Share',
      additionalInfo: 'Supply additional information',
    },
    generalInformation: {
      tabs: {
        overview: '1.1 Overview',
        learningTarget: '1.2 Learning objectives',
        advancedKnowledge: '1.3 Prior knowledge',
        teachingProcess: '1.4 Lesson development',
      },
      teachingProcess: {
        teachingProcessForEachGroupTitle:
          'Diversified learning goals: learning development of each group',
      },
      fields: {
        educationLevel: {
          label: 'Primary school / Secondary school',
          options: {
            primary: 'Primary school',
            secondary: 'Secondary school',
          },
        },
        classLevel: {
          label: 'Grade',
        },
        subject: {
          label: 'Subject',
          placeholder: 'Select subject',
        },
        learningUnit: {
          label: 'Learning strand',
          placeholder:
            'e.g. General Studies for Primary school - "Health and Living", "People and Environment" ',
        },
        teachingTopic: {
          label: 'Topic',
          placeholder: 'Topic',
        },
        sectionTotal: {
          label: 'Number of lessons',
          placeholder: 'Quantity',
        },
        sectionDuration: {
          label: 'Time for each lesson',
          placeholder: 'Class time',
        },
        minute: 'Minutes',
        academicYear: {
          displayFormat: `{{academicYearStart}} - {{academicYearEnd}}`,
          label: 'Academic year',
          placeholder: 'Select academic year ',
        },
        teachingClass: {
          label: 'Class',
          placeholder: 'Class',
        },
        classCharacteristics: {
          label: `Learners' characteristics`,
          placeholder: `Select learners' characteristics`,
        },
        authorName: {
          label: `Teacher's full name`,
          placeholder: `Teacher's full name`,
        },
        date: {
          label: 'Date',
          placeholder: 'DD/MM/YYYY',
        },
        teachingType: {
          label: 'Whole class/ learning objectives of each group ',
          options: {
            basedOnStudentTraitGroups: 'Diverse learning objectives',
            wholeClass: 'Learning objectives of the class',
          },
          remark: 'Remarks',
          changeTypeConfirmTitle:
            'Please note: Previous content WILL NOT be retained if the selection is changed from “Learning objectives of the class” to “Diverse learning objectives”, and vice versa. ',
        },
        wholeClassTeachingType: {
          fields: {
            teachingContents: {
              label: 'Key concept(s) to be taught (if applicable)',
              placeholder: 'Key concept to be taught',
            },
            expectedResultLessons: {
              label: 'Expected learning outcomes',
              subLabel: 'At the end of the class, students can:',
              placeholder: 'Expected learning outcome',
            },
          },
        },
        basedOnStudentTraitGroupsTeachingType: {
          characteristic: {
            label: 'Group characteristics',
            placeholder:
              'Advanced/Foundation/Special support needed (if others, please specify)',
          },
          teachingContents: {
            label:
              'Key concept(s) to be taught in group learning (if applicable)',
            placeholder: 'Key concept(s) to be taught in tiered teaching',
          },
          expectedResultLessons: {
            label: 'Expected learning outcomes',
            placeholder: 'Expected learning outcomes',
          },
        },
        advancedKnowledge: {
          requiredKnowledge: {
            label: `Students' prior knowledge`,
            placeholder: 'Prior knowledge',
          },
          expectedDifficulty: {
            label: `Expected students' learning difficulties`,
            placeholder: 'Input expected learning difficulties',
          },
          remark: {
            label: 'Remark',
            placeholder: 'Supplementary information ',
          },
        },
        wholeClassTeachingProcess: {
          teachingFlow: {
            duration: {
              label: 'Time (minutes)',
              placeholder: 'Time',
            },
            activity: {
              label: 'Learning activities',
              placeholder: 'Activity',
            },
            description: {
              label: 'Activity description',
              placeholder: 'Activity description',
            },
            diStrategyButton: 'DI strategies',
            order: 'Order',
          },
        },
        followUpItems: {
          label: 'Follow-up items',
          placeholder: 'Quiz/Homework (if any)',
        },
        learningResource: {
          label: 'Self - learning resources/ extended learning',
          placeholder: 'Supplementary information',
        },
      },
      teachingStrategyModal: {
        title: 'DI strategies',
        maxNumberWarning:
          'You should not select more than {{maxDIStrategyNumber}} DIs',

        actions: {
          close: 'Close',
          submit: 'Submit',
        },
      },
    },
    diStrategies: {
      title: 'DI strategies',
      subTitle:
        '( Select 3 - 5 for a single lesson; select 5 - 8 for modules )',
      reasonForSelect: {
        label: 'Reasons to select the above DI strategies',
        placeholder: 'Reason',
      },
      selectedStrategies: 'Selected DI strategies',
      label:
        '{{diStrategyClassLabel}}{{diStrategyLabelNumber}}. {{diStrategyName}}',
    },
    diStrategyClass: {
      label: '{{label}}. {{name}}',
    },
    uploadDocuments: {
      label: `1.	Please upload all teaching materials and lesson planning ideas (could be in forms of text, audio or video) in the ‘Add Attachment’ section and select appropriate ‘Resource Type’.\n2.	Please enter the ‘Title’, ‘URL link’ and select the ‘Resource Type’ in the ‘Insert Link’ section if online learning tool is used for teaching (e.g., GeoGebera or Nearpod) OR when storage service is used (e.g., One Drive or Google Drive) to upload large files.
        `,
      title: 'Enter the title',
      url: 'Enter URL',
      resourceType: 'Resources type',
    },
    shareAndRequestPublish: {
      shareTo: 'Share with',
      shareInputPlaceholder: '@ newmember@ccss.edu.hk',
      diStrategyTeachingConceptExcerpt: 'Lesson Design for DI',
      schoolBackgroundInformation: 'School Background',
    },
    requestConsultingPopup: {
      title: `Seek for expert's comments`,
      label: 'Enquiry about DI strategies and related contents',
      placeholder: 'Enquiry on content',
      requireObservation:
        'Wish to have experts to observe my lesson (recorded or face-to-face)',
      confirmation: {
        title: 'Confirm to seek for consultation',
        content: 'Confirm the submission to consult experts?',
      },
    },
    saveSuccess: 'Lesson plan is saved!',
    uploadSuccess: 'Lesson plan/material is uploaded successfully!',
    sharingsSuccess: 'Lesson plan/material is uploaded successfully!',
    updateSharingsSuccess: 'The list is updated!',
  },

  actions: {
    requestConsulting: 'Consult expert',
    return: 'Back',
    saveAndContinue: 'Save and continue',
    saveAndView: 'Save and review',
    sharedWithOthers: 'Share',
    addUrl: 'Add',
  },

  educationLevel: {
    primary: 'Primary school',
    secondary: 'Secondary school',
  },

  consultRequestStatus: {
    pending: 'To follow up',
    followingUp: 'In progress',
    completed: 'Replied',
    noPendingRequest: '---',
  },
  publishRequestStatus: {
    publishing: 'Published',
    pending: 'Sent',
    approved: 'Accepted',
    rejected: 'Rejected',
    completed: 'Completed',
    unPublishing: 'Removed',
    noPendingRequest: 'Preparing',
    cancelled: 'Withdrawn',
  },

  status: {
    created: 'Preparing',
    toBeViewed: 'To be reviewed',
    agreeToPublish: 'Sent', // deprecated
    toBeProcess: 'Sent',
    accepted: 'Accepted', // deprecated
    approved: 'Accepted',
    rejected: 'Rejected',
    published: 'Published',
    toBeProcessed: 'To follow up',
    replied: 'Replied',
    followingUp: 'In progress',
    replyReceived: 'Reply received',
  },

  detail: {
    actions: {
      transferOwnership: 'Transfer ownership',
      agreeToPublish: 'Submit lesson plan',
      withdraw: 'Remove submitted draft',
      editLessonPlan: 'Edit lesson plan',
      accept: 'Accept submitted draft',
      reject: 'Reject submitted draft',
      withdrawApproval: 'Withdrawn accepted',
      publish: 'Publish',
      unpublish: 'Cancel publication',
    },
    basic: {
      dateCreated: 'Create date',
      datePublished: 'Publish date ',
      school: 'School',
      owner: 'Author',
      subjectRelated: 'Field',
      latestUpdated: 'Last updated on',
      actionOptions: 'Option',
      status: 'Lesson plans status',
      consultDisplayStatus: 'Consultation status',
      publishDisplayStatus: 'Draft submission status ',
      isAgreePublish: 'Consent to publish',
    },
    assignExpert: {
      createdByUser: 'Name of teacher',
      consultDetail: 'Consult',
      assignedToUser: 'Expert-in-charge',
      assignedDate: 'Appointment date',
      lastModifiedDateTime: 'Last updated on',
      status: {
        notComplete: 'Awaiting for reply',
        completed: 'Replied',
      },

      assign: {
        title: 'Appoint expert',
        email: '@ newmember@ccss.edu.hk',
        actions: {
          close: 'Close',
          confirm: 'Confirm',
        },
      },

      actions: {
        assignExpert: 'Appoint expert',
      },
    },
    expectedDifficulty: `Learners' characteristics and identified learning difficulties`,
    general: {
      menuTab: {
        teachingPlan: 'Lesson plan',
        diStrategies: 'DI strategies',
        additionalTextbook: 'Teaching materials',
        schoolBackground: 'School Background',
        designExplanation: 'Lesson Design for DI',
      },
      designExplanation: 'Lesson plan design rationale',
      annex: 'Attachment ({{count}})',
      link: 'Link ({{count}})',
      teachingPlanTab: {
        educationLevel: 'Primary school / Secondary school',
        classLevel: 'Grade',
        subject: 'Subject',
        learningUnit: 'Learning strand',
        teachingTopic: 'Topic',
        sectionTotal: 'Number of sections',
        sectionDuration: 'Time of each session',
        durationUnit: 'Minutes',
        academicYear: 'Academic year',
        teachingClass: 'Class',
        lessonPlanTags: {
          classCharacteristic: `Learners' characteristics`,
        },

        teachingProcess: {
          processOfEachGroup: {
            title:
              'Diversified learning goals: learning development of each groups',
            timeInMinute: 'Time (minutes)',
            activity: 'Learning activities',
            strategy: 'DI strategies',
            description: 'Activity description',
          },
        },
      },
      diStrategiesTab: {
        title: 'Selected DI strategies',
      },
    },
    lessonPlanComments: {
      reply: 'Response',
      inputContent: 'Publish a response ...',
      expand: 'Show more responses',
      collapse: 'Hide responses ',
      deletedComment: 'This message has been deleted',
      actions: {
        reply: 'Quote reply',
        edit: 'Edit',
        delete: 'Delete',
        create: 'Add comment',
      },
      consultComment: 'Consultation',
      commentActionModal: {
        edit: 'Edit reply',
        reply: 'Quote reply',
        delete: 'Delete reply',
        deleteMessage: 'Confirm to delete this reply?',
        replyContent: 'Input text',
        actions: {
          send: 'Send',
          close: 'Close',
          cancel: 'Cancel',
          confirm: 'Confirm',
        },
      },
    },
    publishRequest: {
      withdraw: {
        title: 'Remove submitted draft',
        content: 'Confirm to remove lesson plan?',
      },
      approve: {
        title: 'Confirm draft submission',
        content: 'Confirm to submit lesson plan?',
        teacherIntention: `Teacher's intention`,
        confirmation: 'Agree to share lesson design',
        acknowledgement:
          'If clicking this box, you will give consent to our project team to share the lesson plan and materials with other schools.',
        remind:
          'The endorsed lesson plan and materials can be shared with other schools.',
      },
      actions: {
        close: 'Close',
        confirm: 'Confirm',
      },
    },
    transferOwnership: {
      title: 'Confirm ownership transferral',
      content: 'Confirm to transfer the ownership of this lesson plan?',
      field: {
        label:
          'The ownership of this lesson will be transferred to the following user account:',
        placeholder: '@ newmember@ccss.edu.hk',
      },
      remark: 'Note: This operation cannot be restored',
    },
  },
  errorMessage: {
    createLessonPlan: { title: 'Lesson plan failed to publish' },
    updateLessonPlan: { title: 'Lesson plan failed to update' },
    createLessonPlanSharings: { title: 'Lesson materials failed to share' },

    createLessonPlanComment: { title: 'Comments cannot be added' },
    updateLessonPlanComment: { title: 'Comments cannot be updated' },
    deleteLessonPlanSharings: { title: 'Sharing list cannot be updated' },
    deleteLessonPlanComment: { title: 'Comments cannot be deleted' },

    createLessonPlanPublishRequest: { title: 'Draft failed to submit' },
    withdrawLessonPlanPublishRequest: {
      title: 'Draft submission cannot be removed',
    },

    approveLessonPlanPublishRequest: {
      title: 'Draft submission cannot be accepted',
    },
    rejectLessonPlanPublishRequest: {
      title: 'Draft submission cannot be rejected',
    },
    completeAndPublishLessonPlanPublishRequest: { title: 'Failed to publish' },
    createLessonPlanConsultRequest: {
      title: 'Consultation request cannot be submitted',
    },
    assignExpert: { title: 'Fail to appoint expert' },
    completeConsultRequest: { title: 'Fail to reply consultation' },
    transferLessonPlan: { title: 'Lesson plan ownership failed to transfer' },
  },
  action: {
    createLessonPlanComment: { successMessage: 'Comments added successfully' },
    updateLessonPlanComment: {
      successMessage: 'Comments updated successfully',
    },
    deleteLessonPlanSharings: {
      successMessage: 'Sharing list updated successfully',
    },
    deleteLessonPlanComment: {
      successMessage: 'Comments deleted successfully',
    },

    createLessonPlanPublishRequest: {
      successMessage: 'Draft submission is successful',
    },
    withdrawLessonPlanPublishRequest: {
      successMessage: 'Draft submission is removed',
    },
    createLessonPlanConsultRequest: {
      successMessage:
        'Thank you for submitting your lesson plan and teaching materials！ The expert may need up to 2 weeks to provide feedback if you seek for expert’s advice.',
    },
    assignExpert: { successMessage: 'Expert is appointed' },
    transferLessonPlan: {
      successMessage:
        'The ownership of the lesson plan has been successfully transferred!',
    },
  },
  filter: {
    educationLevel: 'Primary school / Secondary school',
    subject: 'Any subject',
    learningPhase: 'Learning phases',
    diStrategyClass: 'DI strategies classification',
    diStrategy: 'DI strategies',
    teachingTopic: 'Name of lesson plans',
    resourceType: 'Resources type',
  },
}
