import { useTranslation } from 'react-i18next'
import { ButtonBase, Grid, Tooltip, Typography } from '@mui/material'
import { memo, useCallback, useMemo } from 'react'
import { Svg } from '../../assets'
import {
  NotificationPayload,
  NotificationType,
  NotificationTypeEnum,
} from '@polyu-dip/apis'
import styled from 'styled-components'
import { Avatar } from '../avatar'
import { BadgeIcon } from '../user'
import dayjs from 'dayjs'
import { Tag } from '../tag'
import { ClassLevel, Subject } from '@polyu-dip/models'
import { contentPaths } from '../../content-paths'
import { useDisplayName } from '../../services'
import { useStores } from '../../stores'

const wordLimit = 30

const Container = styled(ButtonBase)`
  padding: ${({ theme }) => theme.spacings.general[3]}px;
  padding-left: 40px;
  padding-right: 40px;
  width: 100%;

  &:hover {
    background-color: ${({ theme }) => theme.palettes.general.greys[4]};
  }
`

const NotificationIcon = styled.div`
  border: 1px solid ${({ theme }) => theme.palettes.general.greys[2]};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const NotificationIconConnectionLine = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.greys[2]};
  width: 30px;
  text-align: center;
`

const ProfilePic = styled.div`
  border-radius: 50%;
  margin: 0 8px 0 0;
  display: flex;
`

const CommentItem = styled(Grid)`
  padding: ${({ theme }) => theme.spacings.general[3]}px;
  padding-left: ${({ theme }) => theme.spacings.general[5]}px;
  border: 2px solid ${({ theme }) => theme.palettes.general.blue};
  width: 100%;
  border-radius: ${({ theme }) => theme.spacings.general[3]}px;
  text-align: left;
`

const TeachingTopicText = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.blue};
`

const DeletedCommentText = styled(Typography)`
  color: ${({ theme }) => theme.palettes.general.greys[2]};
`

const CommentText = styled(Typography)`
  overflow-wrap: break-word;
`

export type NotificationItemProps = {
  onNavigate?: (path: string) => void
  isDisplayConnectionLine?: boolean
  subject?: Subject
  classLevel?: ClassLevel
} & NotificationPayload

export const NotificationItem = memo<NotificationItemProps>(
  ({
    onNavigate,
    isDisplayConnectionLine,
    notificationType,
    comment,
    lessonPlan,
    lessonPlanId,
    lessonPlanPublishRequestId,
    lessonPlanConsultId,
    createdDateTime,
    subject,
    classLevel,
    createdByUser,
  }) => {
    const { t } = useTranslation()
    const { getDisplayName } = useDisplayName()
    const { userProfileStore } = useStores()

    const tooltipRequired = useMemo(
      () => (lessonPlan?.teachingTopic ?? '').length > wordLimit,
      [lessonPlan?.teachingTopic],
    )
    const teachingTopic = useMemo(
      () =>
        tooltipRequired
          ? lessonPlan?.teachingTopic.substring(0, wordLimit).concat('...')
          : lessonPlan?.teachingTopic,
      [lessonPlan?.teachingTopic, tooltipRequired],
    )

    const renderTeachingTopic = useMemo(
      () => (
        <Tooltip title={lessonPlan?.teachingTopic}>
          <TeachingTopicText ml={2} mr={2}>
            {teachingTopic}
          </TeachingTopicText>
        </Tooltip>
      ),
      [lessonPlan?.teachingTopic, teachingTopic],
    )

    const renderNotificationIcon = useMemo(() => {
      switch (notificationType) {
        case NotificationTypeEnum.comment:
        case NotificationTypeEnum.repliedComment:
          return <Svg.Comment fill="black" width={16} height={16} />
        case NotificationTypeEnum.share:
          return <Svg.Tick fill="black" width={16} height={16} />
        case NotificationTypeEnum.consult:
          return <Svg.Letter fill="black" width={16} height={16} />
        case NotificationTypeEnum.assigned:
          return <Svg.Assign fill="black" width={16} height={16} />
        case NotificationTypeEnum.lessonPlanTransferred:
        case NotificationTypeEnum.newLessonPlanFromOwner:
        case NotificationTypeEnum.newLessonPlanFromSchoolAdmin:
          return <Svg.Transfer fill="black" width={16} height={16} />
        case NotificationTypeEnum.userAccountDisabled:
        case NotificationTypeEnum.userAccountReEnabled:
          return <Svg.User fill="black" width={16} height={16} />
        default:
          return <Svg.PublishBlack width={16} height={16} />
      }
    }, [notificationType])

    const renderNotificationContent = useMemo(() => {
      switch (notificationType) {
        case NotificationTypeEnum.share:
        case NotificationTypeEnum.submittedPublishRequest:
        case NotificationTypeEnum.withdrawPublishRequest:
          return (
            <Grid
              container
              direction="row"
              alignItems="center"
              spacing={2}
              wrap="nowrap"
            >
              <Grid item>
                <Typography>
                  {t(
                    `notifications.content.${
                      notificationType as NotificationType
                    }`,
                  )}
                </Typography>
              </Grid>
              <Grid item>
                <Tag
                  label={getDisplayName(classLevel?.name)}
                  colorCustomize={classLevel?.colorCode}
                />
              </Grid>
              <Grid item>
                <Tag
                  label={getDisplayName(subject?.displayName)}
                  colorCustomize={subject?.colorCode}
                />
              </Grid>
              <Grid item>{renderTeachingTopic}</Grid>
            </Grid>
          )
        case NotificationTypeEnum.consult:
        case NotificationTypeEnum.comment:
          return (
            <Grid container direction="row" alignItems="center">
              <Typography>
                {t(
                  `notifications.content.${
                    notificationType as NotificationType
                  }`,
                )}
              </Typography>
              <Grid item>{renderTeachingTopic}</Grid>
            </Grid>
          )
        case NotificationTypeEnum.approvedPublishRequest:
        case NotificationTypeEnum.rejectedPublishRequest:
        case NotificationTypeEnum.published:
        case NotificationTypeEnum.unpublished:
          return (
            <Grid container direction="row" alignItems="center">
              <Typography>
                {t('notifications.content.lessonPlanTeachingTopicPrefix')}
              </Typography>
              <Grid item>{renderTeachingTopic}</Grid>
              <Typography>
                {t(
                  `notifications.content.${
                    notificationType as NotificationType
                  }`,
                )}
              </Typography>
            </Grid>
          )
        default:
          return (
            <Grid container direction="row" alignItems="center">
              <Typography>
                {t(
                  `notifications.content.${
                    notificationType as NotificationType
                  }`,
                )}
              </Typography>
            </Grid>
          )
      }
    }, [
      notificationType,
      t,
      getDisplayName,
      classLevel?.name,
      classLevel?.colorCode,
      subject?.displayName,
      subject?.colorCode,
      renderTeachingTopic,
    ])

    const handleItemOnClick = useCallback(() => {
      if (onNavigate == null) {
        return
      }
      switch (notificationType) {
        case NotificationTypeEnum.share:
          onNavigate(contentPaths.sharedLessonPlans(lessonPlanId ?? ''))
          return
        case NotificationTypeEnum.comment:
        case NotificationTypeEnum.rejectedPublishRequest:
        case NotificationTypeEnum.newLessonPlanFromOwner:
          onNavigate(contentPaths.lessonPlans(lessonPlanId ?? '', 'view'))
          return
        case NotificationTypeEnum.repliedComment:
          if (lessonPlan?.ownedByUserId == userProfileStore.userProfile?.id) {
            onNavigate(contentPaths.lessonPlans(lessonPlanId ?? '', 'view'))
          } else {
            onNavigate(contentPaths.sharedLessonPlans(lessonPlanId ?? ''))
          }
          return
        case NotificationTypeEnum.submittedPublishRequest:
        case NotificationTypeEnum.withdrawPublishRequest:
          onNavigate(
            contentPaths.lessonPlanPublishRequest(
              lessonPlanPublishRequestId ?? '',
              'view',
            ),
          )
          return
        case NotificationTypeEnum.consult:
        case NotificationTypeEnum.assigned:
          onNavigate(
            contentPaths.lessonPlanConsultRequest(lessonPlanConsultId ?? ''),
          )
          return
        case NotificationTypeEnum.approvedPublishRequest:
        case NotificationTypeEnum.unpublished:
          onNavigate(contentPaths.lessonPlanPublishCopy(lessonPlanId ?? ''))
          return
        case NotificationTypeEnum.published:
          onNavigate(contentPaths.lessonPlanExamplesDetail(lessonPlanId ?? ''))
          return
        case NotificationTypeEnum.newLessonPlanFromSchoolAdmin:
        case NotificationTypeEnum.lessonPlanTransferred:
          onNavigate(contentPaths.myLessonPlan())
          return
        default:
          return
      }
    }, [
      onNavigate,
      notificationType,
      lessonPlanId,
      lessonPlan?.ownedByUserId,
      userProfileStore.userProfile?.id,
      lessonPlanPublishRequestId,
      lessonPlanConsultId,
    ])

    const dateDifference = useMemo(
      () => dayjs(dayjs()).diff(createdDateTime, 'day'),
      [createdDateTime],
    )

    return (
      <Container onClick={handleItemOnClick} disabled={onNavigate == null}>
        <Grid container direction="column">
          {isDisplayConnectionLine && (
            <Grid item>
              <NotificationIconConnectionLine>|</NotificationIconConnectionLine>
            </Grid>
          )}
          <Grid container direction="row" flexWrap="nowrap">
            <Grid item xs={0.5} flexShrink={0}>
              <NotificationIcon>
                <Grid item>{renderNotificationIcon}</Grid>
              </NotificationIcon>
            </Grid>
            <Grid container direction="column" flexGrow={1}>
              <Grid
                container
                direction="row"
                alignItems="center"
                flexWrap="nowrap"
              >
                <Grid item flexShrink={0} pr={4} minWidth="180px">
                  <Grid
                    container
                    direction="row"
                    alignItems="center"
                    wrap="nowrap"
                  >
                    <Grid item>
                      <ProfilePic>
                        <Avatar size="small" name={createdByUser?.name} />
                      </ProfilePic>
                    </Grid>
                    <Grid item>
                      <Typography>{createdByUser?.name}</Typography>
                    </Grid>
                    {comment?.isExpert && (
                      <Grid item>
                        <BadgeIcon isExpert />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
                <Grid item flexGrow={1}>
                  <Grid container direction="row" alignItems="center">
                    <Grid item ml={2} mr={2}>
                      {renderNotificationContent}
                    </Grid>
                    <Grid item ml={2} mr={2}>
                      <Typography>·</Typography>
                    </Grid>
                    <Grid item ml={2}>
                      <Typography>
                        {dateDifference < 1
                          ? t('common.today')
                          : t('common.format.dateBefore', {
                              diff: dateDifference,
                            })}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {comment != null &&
                (notificationType == NotificationTypeEnum.comment ||
                  notificationType == NotificationTypeEnum.repliedComment) && (
                  <CommentItem item mt={2}>
                    {comment.isDeleted ? (
                      <DeletedCommentText>
                        {t(
                          'lessonPlan.detail.lessonPlanComments.deletedComment',
                        )}
                      </DeletedCommentText>
                    ) : (
                      <CommentText>{comment.content}</CommentText>
                    )}
                  </CommentItem>
                )}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    )
  },
)
