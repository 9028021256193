export const error = {
  default: {
    title: '錯誤',
    content:
      '我們遇上了錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
    contentWithError: '$t(error.default.content) ({{error}})',
  },

  timeout: {
    title: '連線超時',
    content: '連線超時。',
  },

  cannotConnect: {
    title: '無網絡連接',
    content: '網絡未能連接。',
  },

  forbidden: {
    title: '無權限',
    content: '你没有存取權限',
  },

  unauthorized: {
    title: '未認證',
    content: '你的認證已過期。',
  },

  conflict: {
    title: '數據衝突',
    content: '你正在修改的數據已被更新。請重新整理後重試。',
  },

  notFound: {
    title: '找不到',
    content: '你所存取的數據不存在。',
  },

  serverError: {
    title: '伺服器錯誤',
    content:
      '我們遇上了內部錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
  },

  badRequest: {
    title: '錯誤請求',
    content:
      '我們遇上了請求錯誤，請再試一次。如問題持續發生，請聯絡技術支援。({{error}})',
  },

  action: { retry: '重試' },
  retryContent: '要再試一次嗎？',

  fetchEntityFailure: '檢索 {{entityName}} 失敗',

  errorDetail: '錯誤: {{codes}}\n追跡碼: {{traceId}}',
  required: '必填',
  invalidUrlFormat: '請輸入包含https://的正確下載連結',
  invalidEmail: '請輸入正確的電郵地址',
  invalidSchoolDomain: '請輸入正確的學校網域',
  invalidEmailDomain: '請輸入使用學校網域的電郵地址',
  integer: '請輸入整數',
  decimal: '請輸入小數',
  boolean: '請選擇是否',
  minInteger: '不能少於 {{minValue}}',
  maxInteger: '不能大於 {{maxValue}}',
  invalidHexFormat: '請輸入正確的HEX 色碼',
  duplicatedEmailInSystem: '電郵已被註冊',
  duplicatedEmailInSameRequest: '無法註冊相同電郵',
  duplicatedEntityValue: '{{field}}已被使用',
}
